import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: "/",
    name: "main",
    component: () => import("../views/Main.vue"),
    children: [
      
      {
        path: "",
        name: 'home',
        component: () => import("../views/Home.vue")
      },
      {
        path: "/role",
        name: "role",
        component: () => import("../views/sys/Role.vue")
      },
      {
        path: "/manager",
        name: "manager",
        component: () => import("../views/sys/Manager.vue")
      },
      {
        path: "/navigation",
        name: "navigation",
        component: () => import("../views/sys/Navigation.vue")
      },
      {
        path: "/siteconfig",
        name: "siteconfig",
        component: () => import("../views/sys/SiteConfig.vue")
      },
      {
        path: "/banner",
        name: "banner",
        component: () => import("../views/sys/Banner.vue")
      },
      {
        path: "/articleInfo",
        name: "articleInfo",
        component: () => import("../views/sys/ArticleInfo.vue")
      },
      {
        path: "/articleStatic/:id",
        name: "articleStatic",
        component: () => import("../views/sys/ArticleStatic.vue"),
      },
      {
        path: "/data",
        name: "data",
        component: () => import("../views/sys/Data.vue")
      },
      {
        path: "/category",
        name: "category",
        component: () => import("../views/sys/Category.vue")
      },
      {
        path: "/userlist",
        name: "userlist",
        component: () => import("../views/user/Userlist.vue")
      },
      {
        path: "/collect_year",
        name: "collect_year",
        component: () => import("../views/collect/Year.vue")
      },

      {
        path: "/shopGoods",
        name: "shopGoods",
        component: () => import("../views/mall/ShopGoods.vue")
      },
      {
        path: "/order",
        name: "order",
        component: () => import("../views/mall/Order.vue")
      },
      {
        path: "/comment",
        name: "comment",
        component: () => import("../views/mall/Comment.vue")
      },
      {
        path: "/changeRecord",
        name: "changeRecord",
        component: () => import("../views/mall/ChangeRecord.vue")
      },
      {
        path: "/scoreRecord",
        name: "scoreRecord",
        component: () => import("../views/mall/ScoreRecord.vue")
      },
      {
        path: "/articleCate",
        name: "articleCate",
        component: () => import("../views/mall/ArticleCate.vue")
      },
      {
        path: "/articleList",
        name: "articleList",
        component: () => import("../views/mall/ArticleList.vue")
      },
      {
        path: "/scoreGoods",
        name: "scoreGoods",
        component: () => import("../views/mall/ScoreGoods.vue")
      },
      {
        path: "/scoreOrder",
        name: "scoreOrder",
        component: () => import("../views/mall/ScoreOrder.vue")
      },
      {
        path: "/shopExpress",
        name: "shopExpress",
        component: () => import("../views/mall/ShopExpress.vue")
      },
      {
        path: "/scoreGoodsCate",
        name: "scoreGoodsCate",
        component: () => import("../views/mall/ScoreGoodsCate.vue")
      },
      {
        path: "/coupon",
        name: "coupon",
        component: () => import("../views/this/Coupon.vue")
      },
      {
        path: "/couponRecord",
        name: "couponRecord",
        component: () => import("../views/this/CouponRecord.vue")
      },
      
       //商品编号
       {
        path: "/ShopCode",
        name: "ShopCode",
        component: () => import("../views/mall/ShopCode.vue")
      },
    ]
  },
  {
    path: "/login",
    name: "login",
    component: () => import("../views/Login.vue")
  },
  {
    path: "/abindex",
    name: "abindex",
    component: () => import("../views/above/Index.vue")
  },
  {
    path: "/process",
    name: "process",
    component: () => import("../views/above/Process.vue")
  },
  {
    path: "/detail",
    name: "detail",
    component: () => import("../views/above/Detail.vue")
  },
  {
    path: "/web_newdetail",
    name: "web_newdetail",
    component: () => import("../views/above/Newdetail.vue")
  },
  {
    path: "/prointro",
    name: "prointro",
    component: () => import("../views/above/Prointro.vue")
  },
  {
    path: "/pro2intro",
    name: "pro2intro",
    component: () => import("../views/above/Pro2intro.vue")
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
